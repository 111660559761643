.swiper-button-next {
    @apply text-complementary-gray-dark text-2xl;
}

.swiper-button-next::after {
    @apply text-white text-2xl;
}

.swiper-button-prev {
    @apply text-complementary-gray-dark text-2xl;
}

.swiper-button-prev::after {
    @apply text-white text-2xl;
}

.swiper-pagination-bullet {
    @apply bg-complementary-gray-dark opacity-100
}

.swiper-pagination-bullet-active {
    @apply bg-secondary-gradient
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply -bottom-[5px]
}

.swiper-slide-zoomed>.swiper-zoom-container>img {
    @apply cursor-zoom-out
}

.swiper-zoom-container>img {
    @apply p-10 pt-5
}